<template>
  <section>
    <section>
      <v-row dense>
        <v-col>
          <v-card
              @click="tutorial = true"
              elevation="0"
              color="#EEF4FC"
              class="px-3 py-4 d-flex flex-row justify-content-between align-items-center bg-tutorial">
            <span class="text-black f16 font-weight-bold ms-3 w-50">{{ $t("Tutorial") }}</span>
            <v-dialog v-model="tutorial">
              <v-card class="p-1">
                <v-card-title class="px-2 py-1">
                  <v-icon small @click="tutorial = false">
                    mdi-close
                  </v-icon>
                </v-card-title>
                <v-card-text class="py-1 px-2">
                  <video
                      :key="videoTutorial"
                      width="100%"
                      height="100%"
                      controls
                  >
                    <source
                        :src="videoTutorial"
                        type="video/mp4"
                    >
                  </video>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col>
          <v-card
              elevation="0"
              color="#232126"
              class="px-2 py-2 d-flex flex-column justify-content-between bordered"
          >
            <span class="text-white f13 ms-3">{{ $t("Your wallet") }}</span>
            <div class="bg-wallet pa-2 w-auto d-flex align-items-center justify-content-center">
              <span class="text-white py-1 px-6 bg-dark rounded f13"
              >{{ accountBalance | VMask(currencyMask) }} {{ $t("RIAL") }}</span
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
    </section>

    <!-- Timesheet -->
    <section class="mt-4">
      <div class="d-flex justify-content-between">
        <div>
          <span class="f16 fw-bold">{{ $t("Schedule a patient") }}</span>
        </div>
        <div cols="auto">
          <button @click="gotoSchedulingPage" class="bg-primary text-white  px-2 py-1 button-font rounded">
            {{ $t("All") }}
          </button>
          <span v-show="false">| </span>
          <v-btn
              v-show="false"
              depressed
              plain
              tile
              icon
              @click="gotoSchedulingPage"
          >
            <iconly
                name="plus"
                type="bold"
                class="pointer primary--text w-100 h-100"
            />
          </v-btn>
        </div>
      </div>

      <div v-if="appointments && appointments.length > 0">
        <v-list subheader two-line>
          <v-list-item
              v-for="appointment in appointments"
              :key="appointment.id">
            <v-list-item-avatar>
              <v-avatar>
                <img :src="appointment.userAppointment.clientUserPic ? appointment.userAppointment.clientUserPic : require('@/assets/images/profile.jpg')"
                    :alt="appointment.userAppointment.clientFullName"
                />
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ appointment.userAppointment.clientFullName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ appointment.fromTime }} - {{ appointment.toTime }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon color="green lighten-1">
                  {{ getUserIcon(appointment.userAppointment.sessionType) }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>

      <div
          cols="12"
          class="pt-3"
          v-if="!appointments || appointments.length === 0"
      >
        <v-card color="#EDF6FC" dark>
          <v-card-text
              class="font-weight-bold"
              style="text-align: justify; color: #2fbded"
          >{{ $t("NO_MEETING_SCHEDULED") }}
          </v-card-text
          >
        </v-card>
      </div>
    </section>

    <section class="mt-4">
      <div class="d-flex justify-content-between">
        <div>
          <span class="f16 fw-bold">{{ $t("Exams") }}</span>
        </div>
        <div cols="auto">
          <v-btn
              color="primary"
              depressed
              plain
              link
              min-width="auto"
              min-height="auto"
              height="auto"
              class="primary--text px-2 py-1"
              @click="gotoAllExamsPage"
          >
            {{ $t("All") }}
          </v-btn>
          <span> | </span>
          <!--          <v-btn depressed plain width="25" height="25" small-->
          <!--                 tile icon @click="gotoAllExamsPage">-->
          <!--            <iconly-->
          <!--                name="plus"-->
          <!--                type="bold"-->
          <!--                class="pointer primary&#45;&#45;text"-->
          <!--            />-->
          <!--          </v-btn>-->
          <v-btn depressed plain width="25" height="25" small
                 tile icon @click="gotoAllExamsPage">
            <v-icon color="white" class="bg-primary rounded" small>
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>

      <div v-if="exams && exams.length > 0">
        <v-card
            class="my-2"
            v-for="(exam, index) in exams"
            :key="index"
            flat
            hover
            ripple
            @click="openExamBottomSheet(exam, index)"
        >
          <b-list-group>
            <b-list-group-item class="d-flex align-items-center">
              <v-avatar color="red lighten-5" size="48">
                <span class="red--text text--lighten-1 f18">{{
                    index + 1
                  }}</span>
              </v-avatar>
              <div
                  class="d-flex flex-column ms-4 me-0 align-items-start justify-content-center"
              >
                <span class="f14 font-weight-bold">{{ exam.ename }}</span>
                <span class="f12 grey--text"
                >{{ exam.questionCount }} {{ $t("Question") }}</span
                >
              </div>

              <v-btn
                  :color="exam.bookmarked ? 'primary' : 'grey darken-4'"
                  icon
                  outlined
                  class="ms-auto me-0"
                  elevation="0"
              >
                <v-icon
                    :class="
                    exam.bookmarked
                      ? 'primary--text'
                      : 'grey--text text--darken-4'
                  "
                >mdi-dots-horizontal
                </v-icon>
              </v-btn>
            </b-list-group-item>
          </b-list-group>
        </v-card>
      </div>

    </section>

    <section class="mt-4">
      <div class="d-flex justify-content-between">
        <div>
          <span class="f16 fw-bold">{{ $t("Exercises") }}</span>
        </div>
        <div cols="auto">
          <v-btn
              color="primary"
              depressed
              plain
              link
              min-width="auto"
              min-height="auto"
              height="auto"
              class="primary--text px-2 py-1"
              @click="gotoAllExercisesPage"
          >
            {{ $t("All") }}
          </v-btn>
          <span>| </span>
          <v-btn depressed plain width="25" height="25" small
                 tile icon :to="`/s/exercises/create`">
            <v-icon color="white" class="bg-primary rounded" small>
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>

      <div v-if="exercises && exercises.length > 0">
        <v-card
            class="my-2"
            v-for="(exercise, index) in exercises"
            :key="index"
            flat
            hover
            ripple
            @click="openExerciseBottomSheet(exercise, index)"
        >
          <b-list-group>
            <b-list-group-item class="d-flex align-items-center">
              <v-avatar color="red lighten-5" size="48">
                <span class="red--text text--lighten-1 f18">{{
                    index + 1
                  }}</span>
              </v-avatar>
              <div
                  class="d-flex flex-column ms-4 me-0 align-items-start justify-content-center"
              >
                <span class="f14 font-weight-bold">{{ exercise.ename }}</span>
              </div>

              <v-btn
                  :color="exercise.bookmarked ? 'primary' : 'grey darken-4'"
                  icon
                  outlined
                  class="ms-auto me-0"
                  elevation="0"
              >
                <v-icon
                    :class="
                    exercise.bookmarked
                      ? 'primary--text'
                      : 'grey--text text--darken-4'
                  "
                >mdi-dots-horizontal
                </v-icon>
              </v-btn>
            </b-list-group-item>
          </b-list-group>
        </v-card>
      </div>

    </section>

    <vue-bottom-sheet ref="examBottomSheet">
      <section
          class="d-flex flex-column align-items-center justify-content-center"
      >
        <v-list rounded dense class="w-100">
          <span class="text-center mb-1 d-block f14 font-weight-bold">{{
              selectedExam.name
            }}</span>
          <v-list-item @click="handleExamAssign">
            <v-list-item-icon>
              <iconly name="adduser" type="light" class="primary--text"/>
            </v-list-item-icon>
            <v-list-item-title tag="span">
              {{ $t("Assign to clients") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="handleExamBookmark">
            <v-list-item-icon>
              <iconly
                  v-show="!selectedExam.bookmarked"
                  name="bookmark"
                  type="light"
                  class="primary--text"
              />
              <iconly
                  v-show="selectedExam.bookmarked"
                  name="bookmark"
                  type="bold"
                  :class="'primary--text'"
              />
            </v-list-item-icon>
            <v-list-item-title tag="span" v-if="!selectedExam.bookmarked">
              {{ $t("Add to favorites") }}
            </v-list-item-title>
            <v-list-item-title tag="span" v-else>
              {{ $t("Remove from favorites") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="handleExamPreview">
            <v-list-item-icon>
              <iconly name="show" type="light" class="primary--text"/>
            </v-list-item-icon>
            <v-list-item-title tag="span">
              {{ $t("Preview") }}
            </v-list-item-title>
          </v-list-item>

          <v-btn
              block
              color="primary"
              @click="closeExamBottomSheet"
              class="mt-auto mb-2"
              rounded
          >{{ $t("Close") }}
          </v-btn>
        </v-list>
      </section>
    </vue-bottom-sheet>

    <!--  EXAM  -->
    <vue-bottom-sheet ref="assignExamBottomSheet" v-if="resetedAssignForm">
      <v-form ref="assignExamForm"
              class="d-flex flex-column align-items-center justify-content-center w-100 h-100"
      >
        <div class="w-100">
          <v-text-field
              class="w-100 border"
              solo
              flat
              :label="$t('Search...')"
              prepend-inner-icon="mdi-magnify"
              v-model="searchText"
              @keyup.enter="querySearchFollowersExam"
              hide-details
          ></v-text-field>
        </div>
        <v-radio-group
            v-model="selectedClient"
            mandatory
            color="primary"
            column
            class="w-100"
            v-if="!followersError"
        >
          <v-radio
              v-for="(client, index) in followers"
              :key="index"
              ripple
              :value="client"
              class="ltr"
          >
            <template v-slot:label>
              <v-list-item class="rtl">
                <v-list-item-avatar>
                  <v-img
                      :lazy-src="require('@/assets/images/profile-circle.png')"
                      :src="
                      client.profilePicture != null &&
                      client.profilePicture !== ''
                        ? client.profilePicture
                        : require('@/assets/images/profile.jpg')
                    "
                  >
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                      v-text="`${client.firstName} ${client.lastName}`"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-radio>
        </v-radio-group>

        <div v-else class="my-4">
          <span>{{ followersErrorText }}</span>
        </div>

        <div class="w-100 mt-auto">
          <v-text-field v-mask="currencyMask" class="text-center" outlined light single-line v-model="price"
                        :disabled="isFree"
                        :key="keyPrice" :placeholder="$t('Exam price')" :suffix="$t('RIAL')" type="text"
                        :rules="[(v) => !!v]"
                        required
                        hide-details/>
        </div>

        <div class="w-100">
          <v-checkbox
              class="w-100"
              v-model="isFree"
              :label="$t('Free')"
          ></v-checkbox>
        </div>
        <div class="my-2 w-100">
          <v-btn block color="primary" @click="submitAssignExam" rounded>{{
              $t("Submit")
            }}
          </v-btn>
        </div>
      </v-form>
    </vue-bottom-sheet>


    <!--   Exercises   -->
    <vue-bottom-sheet ref="exerciseBottomSheet">
      <section
          class="d-flex flex-column align-items-center justify-content-center"
      >
        <v-list rounded dense class="w-100">
          <span class="text-center mb-1 d-block f14 font-weight-bold">{{
              selectedExercise.title
            }}</span>
          <v-list-item @click="handleExerciseAssign">
            <v-list-item-icon>
              <iconly name="adduser" type="light" class="primary--text"/>
            </v-list-item-icon>
            <v-list-item-title tag="span">
              {{ $t("Assign to clients") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="handleExerciseBookmark">
            <v-list-item-icon>
              <iconly
                  v-show="!selectedExercise.bookmarked"
                  name="bookmark"
                  type="light"
                  class="primary--text"
              />
              <iconly
                  v-show="selectedExercise.bookmarked"
                  name="bookmark"
                  type="bold"
                  :class="'primary--text'"
              />
            </v-list-item-icon>
            <v-list-item-title tag="span" v-if="!selectedExercise.bookmarked">
              {{ $t("Add to favorites") }}
            </v-list-item-title>
            <v-list-item-title tag="span" v-else>
              {{ $t("Remove from favorites") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="handleExercisePreview">
            <v-list-item-icon>
              <iconly name="show" type="light" class="primary--text"/>
            </v-list-item-icon>
            <v-list-item-title tag="span">
              {{ $t("Preview") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="handleExerciseEdit">
            <v-list-item-icon>
              <iconly name="editsquare" type="light" class="primary--text"/>
            </v-list-item-icon>
            <v-list-item-title tag="span">
              {{ $t("Edit") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="handleExerciseDelete">
            <v-list-item-icon>
              <iconly name="delete" type="light" class="primary--text"/>
            </v-list-item-icon>
            <v-list-item-title tag="span">
              {{ $t("Delete") }}
            </v-list-item-title>
          </v-list-item>

          <v-btn
              block
              color="primary"
              @click="closeExerciseBottomSheet"
              class="mt-auto mb-2"
              rounded
          >{{ $t("Close") }}
          </v-btn>
        </v-list>
      </section>
    </vue-bottom-sheet>

    <vue-bottom-sheet ref="assignExerciseBottomSheet">
      <section
          class="d-flex flex-column align-items-center justify-content-center"
      >
        <div class="w-100">
          <v-text-field
              class="w-100 border"
              solo
              flat
              :label="$t('Search...')"
              prepend-inner-icon="mdi-magnify"
              v-model="searchText"
              @keyup.enter="querySearchFollowersExercise"
              hide-details
          ></v-text-field>
        </div>
        <v-radio-group
            v-model="selectedClient"
            mandatory
            color="primary"
            column
            class="w-100"
            v-if="!followersError"
        >
          <v-radio
              v-for="(client, index) in followers"
              :key="index"
              ripple
              :value="client"
              class="ltr"
          >
            <template v-slot:label>
              <v-list-item class="rtl">
                <v-list-item-avatar>
                  <v-img
                      :lazy-src="require('@/assets/images/profile-circle.png')"
                      :src="
                      client.profilePicture != null &&
                      client.profilePicture != ''
                        ? client.profilePicture
                        : require('@/assets/images/profile.jpg')
                    "
                  >
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                      v-text="`${client.firstName} ${client.lastName}`"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-radio>
        </v-radio-group>

        <div v-else class="my-4">
          <span>{{ followersErrorText }}</span>
        </div>

        <div class="mt-auto w-100">
          <span class="d-inline-block ms-0 me-2">{{
              $t("Exercise time:")
            }}</span>
          <v-otp-input
              length="1"
              type="number"
              v-model="availablePeriod"
              hide-spin-buttons
              class="d-inline-block w-20"
          >
          </v-otp-input>
          <span class="d-inline-block ms-2 me-0">{{ $t("Week") }}</span>
        </div>

        <div class="my-2 w-100">
          <v-btn block color="primary" @click="submitAssignExercise" rounded>{{
              $t("Submit")
            }}
          </v-btn>
        </div>
      </section>
    </vue-bottom-sheet>

    <vue-bottom-sheet ref="previewExerciseBottomSheet">
      <span class="text-center mb-1 d-block f14 font-weight-bold">{{
          selectedExercise.title
        }}</span>
      <p class="text-justify" v-html="selectedExercisePreview.description"></p>
    </vue-bottom-sheet>
  </section>
</template>
<script>
import apiService from "@/http/ApiService";

import createNumberMask from "text-mask-addons/dist/createNumberMask";


const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  components: {},
  data() {
    return {
      currencyMask,
      keyPrice: 0,
      isFree: false,
      price: '',
      resetedAssignForm: false,

      accountBalance: 0,
      exams: [],
      exercises: [],
      appointments: [],

      selectedExam: {},
      selectedExamPostition: -1,

      selectedExercise: {},
      selectedExercisePreview: {},
      selectedExercisePostition: -1,

      searchText: null,
      followers: [],
      followerPage: 1,
      followerSize: 12,
      followersError: false,
      followersErrorText: "",

      selectedClient: null,

      availablePeriod: null,

      videoTutorial: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
      tutorial: false,
    };
  },
  methods: {
    getDoctorAccountBalance() {
      apiService
          .getDoctorAccountBalance()
          .then((response) => {
            this.accountBalance = response.data.cash;
          })
          .catch((err) => {
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t("OK"),
            });
          });
    },
    getExamAssignedTopList() {
      apiService
          .getExamAssignedTopList(5)
          .then((response) => {
            this.exams = response.data;
          })
          .catch((err) => {
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t("OK"),
            });
          });
    },
    getExerciseAssignedTopList() {
      apiService
          .getExerciseAssignedTopList(5)
          .then((response) => {
            this.exercises = response.data;
          })
          .catch((err) => {
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t("OK"),
            });
          });
    },
    gotoAllExamsPage() {
      this.$router.push("/s/exams");
    },
    gotoAllExercisesPage() {
      this.$router.push("/s/exercises");
    },

    //EXAM
    openExamBottomSheet(exam, index) {
      this.resetedAssignForm = true
      this.$refs.examBottomSheet.open();
      this.selectedExam = exam;
      this.selectedExamPostition = index;
    },
    closeExamBottomSheet() {
      this.$refs.examBottomSheet.close();
      this.selectedExam = {};
      this.selectedExamPostition = -1;
      this.resetExamBottomSheet();
    },
    resetExamBottomSheet() {
      this.selectedClient = null;
      this.isFree = false;
      this.price = '';
    },
    closeAssignExamBottomSheet() {
      this.resetAssignExamBottomSheet();
      this.selectedExam = {};
      this.selectedExamPostition = -1;
      this.resetedAssignForm = false
      this.$refs.assignExamBottomSheet.close();
    },
    resetAssignExamBottomSheet() {
      this.selectedClient = null;
      this.isFree = false;
      this.price = '';
    },
    handleExamAssign() {
      this.$refs.examBottomSheet.close();
      if (this.selectedExam.bookmarked) {
        this.getAllFollowersExam(0, 50);
      } else {
        this.$swal({
          icon: "warning",
          text: this.$t(
              "Please add the exam to your favorites before sending it to client"
          ),
          confirmButtonText: this.$t("OK"),
        });
      }
    },
    getAllFollowersExam(page, size) {
      apiService
          .getAllFollowers(page, size)
          .then((response) => {
            if (
                response &&
                response.data &&
                response.data.content &&
                response.data.content.length
            ) {
              this.followersError = false;
              this.followers = response.data.content;

              setTimeout(() => {
                this.$refs.assignExamBottomSheet.open();
              }, 100);
            } else {
              this.followersError = true;
              this.followersErrorText = this.$t(
                  "You have no followers. The client must follow you to submit the exam"
              );
              setTimeout(() => {
                this.$refs.assignExamBottomSheet.open();
              }, 100);
            }
          })
          .catch(() => {
            this.followersError = true;
            this.followersErrorText = this.$t(
                "You have no followers. The client must follow you to submit the exam"
            );
            setTimeout(() => {
              this.$refs.assignExamBottomSheet.open();
            }, 100);
          });
    },
    querySearchFollowersExam() {
      if (this.searchText) {
        this.followers = [];
        this.$refs.assignExamBottomSheet.close();
        apiService
            .querySearchFollowers(this.searchText)
            .then((response) => {
              if (response && response.data && response.data.length) {
                this.followersError = false;
                this.followers = response.data;
                setTimeout(() => {
                  this.$refs.assignExamBottomSheet.open();
                }, 100);
              } else {
                this.followersError = true;
                this.followersErrorText = this.$t("No results found");
                setTimeout(() => {
                  this.$refs.assignExamBottomSheet.open();
                }, 100);
              }
            })
            .catch(() => {
              this.followersError = true;
              this.followersErrorText = this.$t("Search error");
              setTimeout(() => {
                this.$refs.assignExamBottomSheet.open();
              }, 100);
            });
      } else {
        this.getAllFollowersExam(0, 50);
      }
    },
    submitAssignExam() {
      if (this.selectedClient == null || this.selectedClient === {}) {
        this.$refs.assignExamForm.reset()
        this.closeAssignExamBottomSheet()
        this.$swal({
          icon: "warning",
          text: this.$t("No client selected"),
          confirmButtonText: this.$t("OK"),
        });
      } else {
        if (!this.isFree) {
          if (this.price !== null && this.price !== '') {
            var examAssignDTO = {
              userUsername: this.selectedClient.mobileNumber,
              examFavoriteId: this.selectedExam.favoriteId,
              examId: this.selectedExam.eid,
              price: parseFloat(this.price.replace(/,/g, "")),
            };
            apiService
                .examAssignToUser(examAssignDTO)
                .then(() => {
                  this.$refs.assignExamForm.reset()
                  this.closeAssignExamBottomSheet()

                  this.$swal({
                    icon: "success",
                    text: this.$t("Exam assined to client"),
                    confirmButtonText: this.$t("OK"),
                  });
                })
                .catch((err) => {
                  this.closeAssignExamBottomSheet()
                  this.$refs.assignExamForm.reset()
                  this.$swal({
                    icon: "warning",
                    text: err ? err.response.data.detail : this.$t("Error"),
                    confirmButtonText: this.$t("OK"),
                  });
                });
          } else {
            this.$refs.assignExamForm.reset()
            this.closeAssignExamBottomSheet()
            this.$swal({
              icon: "warning",
              text: this.$t(
                  "Please enter the price of the exam or check for free"
              ),
              confirmButtonText: this.$t("OK"),
            });
          }
        } else {
          var examAssignDTO = {
            userUsername: this.selectedClient.mobileNumber,
            examFavoriteId: this.selectedExam.favoriteId,
            examId: this.selectedExam.eid,
            price: 0,
          };
          apiService
              .examAssignToUser(examAssignDTO)
              .then(() => {
                this.$refs.assignExamForm.reset()
                this.closeAssignExamBottomSheet()
                this.$swal({
                  icon: "success",
                  text: this.$t("Exam assined to client"),
                  confirmButtonText: this.$t("OK"),
                });
              })
              .catch((err) => {
                this.$refs.assignExamForm.reset()
                this.closeAssignExamBottomSheet()
                this.$swal({
                  icon: "warning",
                  text: err ? err.response.data.detail : this.$t("Error"),
                  confirmButtonText: this.$t("OK"),
                });
              });
        }
      }
    },
    handleExamBookmark() {
      if (this.selectedExam.bookmarked) {
        let examAssignId;
        if (this.selectedExam.examAssignId === null) {
          examAssignId = -1;
        } else {
          examAssignId = this.selectedExam.examAssignId;
        }
        apiService
            .examDeleteFromFavoriteByAssignId(this.selectedExam.favoriteId, examAssignId)
            .then(() => {
              this.$refs.examBottomSheet.close();
              this.$swal({
                icon: "success",
                text: this.$t("The exam was removed from the favorites"),
                confirmButtonText: this.$t("OK"),
              });
              this.items[this.selectedExamPostition].bookmarked = false;
            })
            .catch((err) => {
              this.$refs.examBottomSheet.close();
              this.$swal({
                icon: "error",
                text: err ? err.response.data.detail : this.$t("Error"),
                confirmButtonText: this.$t("OK"),
              });
            });
      } else {
        var examFavoriteDTO = {
          drUsername: this.$store.getters.doctorId,
          examId: this.selectedExam.eid,
          price: 0,
        };
        apiService
            .examAddToFavorite(examFavoriteDTO)
            .then((response) => {
              this.$refs.examBottomSheet.close();
              this.$swal({
                icon: "success",
                text: this.$t("The exam was added to the favorites"),
                confirmButtonText: this.$t("OK"),
              });

              this.items[this.selectedExamPostition].bookmarked = true;
              this.items[this.selectedExamPostition].examFavoriteId =
                  response.data.id;
            })
            .catch((err) => {
              this.$refs.examBottomSheet.close();
              this.$swal({
                icon: "error",
                text: err ? err.response.data.detail : this.$t("Error"),
                confirmButtonText: this.$t("OK"),
              });
            });
      }
    },
    handleExamPreview() {
      this.$refs.examBottomSheet.close();
      this.$router.push(`/s/exams/preview/${this.selectedExam.eid}`);
    },

    //Exercise
    openExerciseBottomSheet(exercise, index) {
      this.resetExerciseBottomSheet();
      this.$refs.exerciseBottomSheet.open();
      this.selectedExercise = exercise;
      this.selectedExercisePostition = index;
    },
    closeExerciseBottomSheet() {
      this.$refs.exerciseBottomSheet.close();
      this.selectedExercise = {};
      this.selectedExercisePostition = -1;
    },
    resetExerciseBottomSheet() {
      this.selectedClient = null;
      this.availablePeriod = "1";
    },
    handleExerciseAssign() {
      this.$refs.exerciseBottomSheet.close();
      if (this.selectedExercise.bookmarked) {
        this.getAllFollowersExercise(0, 50);
      } else {
        this.$swal({
          icon: "warning",
          text: this.$t(
              "Please add the exercise to your favorites before sending it to client"
          ),
          confirmButtonText: this.$t("OK"),
        });
      }
    },
    getAllFollowersExercise(page, size) {
      apiService
          .getAllFollowers(page, size)
          .then((response) => {
            if (
                response &&
                response.data &&
                response.data.content &&
                response.data.content.length
            ) {
              this.followersError = false;
              this.followers = response.data.content;

              setTimeout(() => {
                this.$refs.assignExerciseBottomSheet.open();
              }, 100);
            } else {
              this.followersError = true;
              this.followersErrorText = this.$t(
                  "You have no followers. The client must follow you to submit the exercise"
              );
              setTimeout(() => {
                this.$refs.assignExerciseBottomSheet.open();
              }, 100);
            }
          })
          .catch(() => {
            this.followersError = true;
            this.followersErrorText = this.$t(
                "You have no followers. The client must follow you to submit the exercise"
            );
            setTimeout(() => {
              this.$refs.assignExerciseBottomSheet.open();
            }, 100);
          });
    },
    querySearchFollowersExercise() {
      if (this.searchText) {
        this.followers = [];
        this.$refs.assignExerciseBottomSheet.close();
        apiService
            .querySearchFollowers(this.searchText)
            .then((response) => {
              if (response && response.data && response.data.length) {
                this.followersError = false;
                this.followers = response.data;
                setTimeout(() => {
                  this.$refs.assignExerciseBottomSheet.open();
                }, 100);
              } else {
                this.followersError = true;
                this.followersErrorText = this.$t("No results found");
                setTimeout(() => {
                  this.$refs.assignExerciseBottomSheet.open();
                }, 100);
              }
            })
            .catch(() => {
              this.followersError = true;
              this.followersErrorText = this.$t("Search error");
              setTimeout(() => {
                this.$refs.assignExerciseBottomSheet.open();
              }, 100);
            });
      } else {
        this.getAllFollowersExercise(0, 50);
      }
    },
    submitAssignExercise() {
      if (this.selectedClient == null || this.selectedClient == {}) {
        this.$refs.assignExerciseBottomSheet.close();
        this.$swal({
          icon: "warning",
          text: this.$t("No client selected"),
          confirmButtonText: this.$t("OK"),
        });
      } else {
        try {
          var ap = parseInt(this.availablePeriod);
          if (
              this.availablePeriod.trim() != "" &&
              this.availablePeriod != null &&
              ap > 0
          ) {
            var exerciseAssignDTO = {
              patientUsername: this.selectedClient.mobileNumber,
              exerciseId: this.selectedExercise.eid,
              favoriteId: this.selectedExercise.favoriteId,
              availablePeriod: ap,
            };
            apiService
                .exerciseAssignToUser(exerciseAssignDTO)
                .then(() => {
                  this.$refs.assignExerciseBottomSheet.close();
                  this.$swal({
                    icon: "success",
                    text: this.$t("Exercise assined to client"),
                    confirmButtonText: this.$t("OK"),
                  });
                })
                .catch((err) => {
                  this.$refs.assignExerciseBottomSheet.close();
                  this.$swal({
                    icon: "warning",
                    text: err ? err.response.data.detail : this.$t("Error"),
                    confirmButtonText: this.$t("OK"),
                  });
                });
          } else {
            this.$refs.assignExerciseBottomSheet.close();
            this.$swal({
              icon: "warning",
              text: this.$t(
                  "Please enter the exercise time correctly and more than zero"
              ),
              confirmButtonText: this.$t("OK"),
            });
          }
        } catch {
          this.$refs.assignExerciseBottomSheet.close();
          this.$swal({
            icon: "warning",
            text: this.$t(
                "Please enter the exercise time correctly and more than zero"
            ),
            confirmButtonText: this.$t("OK"),
          });
        }
      }
    },
    handleExerciseBookmark() {
      if (this.selectedExercise.bookmarked) {
        apiService
            .exerciseDeleteFromFavorite(this.selectedExercise.favoriteId)
            .then(() => {
              this.$refs.exerciseBottomSheet.close();
              this.$swal({
                icon: "success",
                text: this.$t("The exercise was removed from the favorites"),
                confirmButtonText: this.$t("OK"),
              });
              this.items[this.selectedExercisePostition].bookmarked = false;
            })
            .catch((err) => {
              this.$refs.exerciseBottomSheet.close();
              this.$swal({
                icon: "error",
                text: err ? err.response.data.detail : this.$t("Error"),
                confirmButtonText: this.$t("OK"),
              });
            });
      } else {
        var exerciseFavoriteDTO = {
          drUsername: this.$store.getters.doctorId,
          exerciseId: this.selectedExercise.eid,
          price: 0,
        };
        apiService
            .exerciseAddToFavorite(exerciseFavoriteDTO)
            .then((response) => {
              this.$refs.exerciseBottomSheet.close();
              this.$swal({
                icon: "success",
                text: this.$t("The exercise was added to the favorites"),
                confirmButtonText: this.$t("OK"),
              });

              this.items[this.selectedExercisePostition].bookmarked = true;
              this.items[this.selectedExercisePostition].favoriteId =
                  response.data.id;
            })
            .catch((err) => {
              this.$refs.exerciseBottomSheet.close();
              this.$swal({
                icon: "error",
                text: err ? err.response.data.detail : this.$t("Error"),
                confirmButtonText: this.$t("OK"),
              });
            });
      }
    },
    handleExercisePreview() {
      this.$refs.exerciseBottomSheet.close();
      apiService
          .exercisePreview(this.selectedExercise.eid)
          .then((response) => {
            this.$refs.previewExerciseBottomSheet.open();
            this.selectedExercisePreview = response.data;
          })
          .catch((err) => {
            this.$swal({
              icon: "error",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t("OK"),
            });
          });
    },
    handleExerciseEdit() {
      this.$refs.exerciseBottomSheet.close();
      this.$router.push(`/s/exercises/edit/${this.selectedExercise.eid}`);
    },
    handleExerciseDelete() {
      this.$refs.exerciseBottomSheet.close();
      this.$swal({
        icon: "warning",
        showDenyButton: true,
        text: this.$t("Are you sure you want to delete the exercise?"),
        confirmButtonText: this.$t("Yes"),
        denyButtonText: this.$t("No"),
      }).then((result) => {
        if (result.isConfirmed) {
          apiService.deleteExercise(this.selectedExercise.eid)
              .then(() => {
                this.getExerciseAssignedTopList();
                this.$swal({
                  icon: "success",
                  text: this.$t("The exercise was successfully deleted"),
                  confirmButtonText: this.$t("OK"),
                });
              })
              .catch((err) => {
                this.$swal({
                  icon: "error",
                  text: this.$t("Deleting exercise failed"),
                  confirmButtonText: this.$t("OK"),
                });
              });
        }
      });
    },
    gotoSchedulingPage() {
      this.$router.push("/s/schedule");
    },
    loadDoctorAppointments() {
      apiService
          .getCurrentDoctorTimesheetDetail()
          .then((res) => {
            this.appointments = res.data.content;
          })
          .catch((err) => {
            this.$swal({
              icon: "error",
              text: this.$t("Deleting exercise failed"),
              confirmButtonText: this.$t("OK"),
            });
          });
    },
    getUserIcon(sessionType) {
      if (sessionType === "ONLINE") {
        return "mdi-video-account";
      } else if (sessionType === "ALL") {
        return "mdi-account-heart";
      } else {
        return "mdi-account-supervisor";
      }
    },
  },
  beforeMount() {
    this.$store.commit("update_backActive", false);
    this.$store.commit("update_helloActive", true);
    this.$store.commit("update_threeDotsActive", false);
    this.$store.commit("update_notificationActive", true);
    this.$store.commit("update_toolbarTitleActive", false);
    this.getDoctorAccountBalance();
    this.getExamAssignedTopList();
    this.getExerciseAssignedTopList();
    this.loadDoctorAppointments();
  },
};
</script>

<style scoped>
.bg-wallet {
  background-image: url("~@/assets/images/app/bg-wallet.svg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.bg-tutorial {
  background-image: url("~@/assets/images/video-tutorial.png");
  background-position: 10%;
  background-repeat: no-repeat;
  background-size: contain;
}

.button-font {
  font-size: .875rem;
}
</style>

